import React, { useState } from 'react';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import apiService from '../services/apiService';


const ContactUs = () => {
    const [form, setForm] = useState({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });

        // Basic validation example
        if (name === 'email') {
            if (!value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
                setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address.' }));
            } else {
                setErrors((prevErrors) => {
                    const { email, ...rest } = prevErrors;
                    return rest;
                });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear form-level errors
        setErrors((prevErrors) => {
            const { _form, ...rest } = prevErrors;
            return rest;
        });

        // Check for required fields
        const newErrors = {};
        if (!form.name) newErrors.name = 'Name is required';
        if (!form.email) newErrors.email = 'Email is required';
        if (!form.message) newErrors.message = 'Message is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
            return;
        }

        try {
            const response = await apiService.contactUs(form);
            if (response.status === 200) {
                setSubmitted(true);
            } else {
                setErrors({ _form: 'Something went wrong. Please try again.' });
            }
        } catch (error) {
            setErrors({ _form: 'Failed to submit the form. Please try again later.' });
        }
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Box sx={{ textAlign: 'left', mb: 4 }}>
                <Typography variant="h4" gutterBottom>Contact Us</Typography>
                {!submitted ? (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={form.name}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ mb: 2 }}
                            error={!!errors.name}
                            helperText={errors.name}
                            required
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={form.email}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ mb: 2 }}
                            error={!!errors.email}
                            helperText={errors.email}  // Should be a string or undefined
                            type="email"
                            required
                        />
                        <TextField
                            label="Message"
                            variant="outlined"
                            name="message"
                            value={form.message}
                            onChange={handleInputChange}
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ mb: 2 }}
                            error={!!errors.message}
                            helperText={errors.message}
                            required
                        />
                        {errors._form && (
                            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                                {errors._form}
                            </Typography>
                        )}
                        <Button variant="contained" color="primary" type="submit">
                            Send Message
                        </Button>
                    </form>
                ) : (
                    <Typography variant="h6">Thank you for contacting us. We will get back to you soon!</Typography>
                )}
            </Box>
        </Container>
    );
};

export default ContactUs;
