import React from 'react';
import { Typography, Button, Grid, Paper, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        "name": "Media Utils",
        "url": "https://mediautils.app",
        "description": "Free online tools to compress, convert, crop, and rotate images.",
        "applicationCategory": "Multimedia",
        "operatingSystem": "All",
        "offers": {
            "@type": "Offer",
            "price": "0"
        }
    };

    return (
        <>
            <Helmet>
                <title>Media Utils - Free Online Image Compression and Conversion Tools</title>
                <meta name="description" content="Media Utils offers free online tools to compress, convert, crop, and rotate images. Fast, easy, and secure with automatic file deletion." />
                <meta name="keywords" content="image compression, image conversion, image cropping, image rotation, online image tools, free image editor" />
                <link rel="canonical" href="https://mediautils.app/" />
                <meta property="og:title" content="Media Utils - Free Online Image Tools" />
                <meta property="og:description" content="Compress, convert, crop, and rotate images online for free. Fast, easy, and secure." />
                <meta property="og:url" content="https://mediautils.app/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Media Utils - Free Online Image Tools" />
                <meta name="twitter:description" content="Compress, convert, crop, and rotate images online for free. Fast, easy, and secure." />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
                <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: '3.5rem', fontWeight: 'bold' }}>Welcome to Media Utils</Typography>
                <Typography variant="p" gutterBottom>
                    Your all-in-one solution for image manipulation. Compress, convert, crop, and rotate images online quickly and easily. Rest assured, your images are automatically deleted within a few hours, ensuring your privacy and security. We don't store any of your files, so you can use our tools with complete peace of mind.
                </Typography>
            </Box>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Typography variant="h5" gutterBottom>Compress Images</Typography>
                        <Typography variant="body1">
                            Reduce the file size of your images without compromising quality. Perfect for web use and saving storage space.
                        </Typography>
                        <Button variant="contained" color="primary" component={Link} to="/compress-image" sx={{ mt: 2 }}>
                            Compress Image
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Typography variant="h5" gutterBottom>Convert Images</Typography>
                        <Typography variant="body1">
                            Convert your images between different formats (JPEG, PNG, GIF, etc.). Simple and fast.
                        </Typography>
                        <Button variant="contained" color="primary" component={Link} to="/convert-image" sx={{ mt: 2 }}>
                            Convert Image
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Typography variant="h5" gutterBottom>Crop Images</Typography>
                        <Typography variant="body1">
                            Easily crop your images to the perfect size and aspect ratio. Get the exact framing you need.
                        </Typography>
                        <Button variant="contained" color="primary" component={Link} to="/crop-image" sx={{ mt: 2 }}>
                            Crop Image
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Typography variant="h5" gutterBottom>Rotate Images</Typography>
                        <Typography variant="body1">
                            Rotate your images left or right. Fix orientation issues quickly.
                        </Typography>
                        <Button variant="contained" color="primary" component={Link} to="/rotate-image" sx={{ mt: 2 }}>
                            Rotate Image
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default HomePage;
